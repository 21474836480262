var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Form",
    {
      staticClass: "auth-form",
      attrs: { action: _vm.onSignin, payload: {} },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function ({ loading }) {
            return [
              _c(
                "Button",
                {
                  attrs: {
                    type: "submit",
                    variant: "contained",
                    color: "primary",
                    loading: loading || _vm.done,
                    disabled: _vm.disabled || _vm.done,
                  },
                },
                [_vm._v("\n      Sign In\n    ")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "demo-title" }, [
                _c("span", [_vm._v("Don't have an account yet? ")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "demo-title",
                    attrs: { href: "https://www.picsellia.com/contact" },
                  },
                  [_vm._v("Request Demo")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "forget-password-title",
                  attrs: { href: "/forgot-password" },
                },
                [_vm._v("Forgot password?")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "header",
        [
          _c("Icon", {
            staticClass: "logo",
            attrs: { name: "brand-picsellia" },
          }),
          _vm._v(" "),
          _c("h1", [_vm._v("Login with")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("TextInput", {
        staticClass: "input",
        attrs: { label: "Username", autocomplete: "username" },
        model: {
          value: _vm.username,
          callback: function ($$v) {
            _vm.username = $$v
          },
          expression: "username",
        },
      }),
      _vm._v(" "),
      _c("TextInput", {
        staticClass: "input",
        attrs: {
          label: "Password",
          type: "password",
          autocomplete: "current-password",
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _vm._v(" "),
      _c("Turnstile", {
        ref: "turnstile",
        attrs: { "site-key": _vm.siteKey },
        model: {
          value: _vm.token,
          callback: function ($$v) {
            _vm.token = $$v
          },
          expression: "token",
        },
      }),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }