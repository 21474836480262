var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("span", [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                _vm.required
                  ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "input-wrapper", class: { invalid: _vm.error } },
        [
          (_vm.showPassword ? "text" : _vm.type) === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valueInput,
                    expression: "valueInput",
                  },
                ],
                ref: "input",
                attrs: {
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  autocomplete: _vm.autocomplete,
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.valueInput)
                    ? _vm._i(_vm.valueInput, null) > -1
                    : _vm.valueInput,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input")
                  },
                  focus: function ($event) {
                    return _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                  keydown: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.$emit("delete")
                    },
                  ],
                  keyup: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("enter")
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("esc")
                    },
                  ],
                  change: function ($event) {
                    var $$a = _vm.valueInput,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.valueInput = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.valueInput = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.valueInput = $$c
                    }
                  },
                },
              })
            : (_vm.showPassword ? "text" : _vm.type) === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valueInput,
                    expression: "valueInput",
                  },
                ],
                ref: "input",
                attrs: {
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  autocomplete: _vm.autocomplete,
                  type: "radio",
                },
                domProps: { checked: _vm._q(_vm.valueInput, null) },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input")
                  },
                  focus: function ($event) {
                    return _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                  keydown: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.$emit("delete")
                    },
                  ],
                  keyup: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("enter")
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("esc")
                    },
                  ],
                  change: function ($event) {
                    _vm.valueInput = null
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.valueInput,
                    expression: "valueInput",
                  },
                ],
                ref: "input",
                attrs: {
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  autocomplete: _vm.autocomplete,
                  type: _vm.showPassword ? "text" : _vm.type,
                },
                domProps: { value: _vm.valueInput },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.valueInput = $event.target.value
                    },
                    function ($event) {
                      return _vm.$emit("input")
                    },
                  ],
                  focus: function ($event) {
                    return _vm.$emit("focus")
                  },
                  blur: function ($event) {
                    return _vm.$emit("blur")
                  },
                  keydown: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.$emit("delete")
                    },
                  ],
                  keyup: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("enter")
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$emit("esc")
                    },
                  ],
                },
              }),
          _vm._v(" "),
          _vm.type === "password"
            ? _c("icon", {
                staticClass: "password-visibility",
                attrs: { name: _vm.showPassword ? "eye" : "eye-closed" },
                on: {
                  click: function ($event) {
                    _vm.showPassword = !_vm.showPassword
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _vm._t("error", function () {
            return [
              _c("span", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }